var render = function () {
  var _vm$agencyToEdit, _vm$agencyToEdit2, _vm$agencyToEdit3, _vm$agencyToEdit3$par, _vm$agencyToEdit4, _vm$agencyToEdit5, _vm$agencyToEdit5$par, _vm$agencyToEdit6, _vm$agenciesData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card-title', {
    staticClass: "py-1 d-flex mb-0",
    staticStyle: {
      "position": "sticky",
      "top": "45px",
      "z-index": "100",
      "background": "white"
    }
  }, [!_vm.isMobileView ? _c('feather-icon', {
    staticClass: "mx-50",
    attrs: {
      "icon": "ToolIcon",
      "size": "24"
    }
  }) : _vm._e(), _c('span', {
    class: _vm.isMobileView ? 'font-medium-2 text-nowrap' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.title')) + " " + _vm._s(_vm.$t('agency.agencies')) + ": "), _c('kbd', {
    staticClass: "text-dark ml-25"
  }, [_vm._v(_vm._s((_vm$agencyToEdit = _vm.agencyToEdit) === null || _vm$agencyToEdit === void 0 ? void 0 : _vm$agencyToEdit.agencyCode))])])], 1), _c('b-card', {
    staticClass: "border mb-1 mt-0",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-50",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between w-100"
        }, [_c('h5', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.permissionTitle')) + " ")]), _c('div', [!_vm.isEditPermission ? _c('BButton', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.isEditPermission = !_vm.isEditPermission;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('edit')) + " ")]) : _c('BButton', {
          attrs: {
            "variant": "primary",
            "disabled": _vm.loadingEditPermission
          },
          on: {
            "click": _vm.handleEditPermission
          }
        }, [_vm.loadingEditPermission ? _c('b-spinner', {
          attrs: {
            "variant": "light",
            "label": "Text Centered",
            "small": "",
            "tag": "span"
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(_vm.loadingEditPermission ? _vm.$t('saving') : _vm.$t('save')) + " ")], 1)], 1)])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "py-0 px-2"
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingEditPermission
    }
  }, [_vm.showBookingLimit ? _c('b-form-group', {
    staticClass: "mt-50",
    attrs: {
      "label": _vm.$t('agency.configs.bookingLimit')
    }
  }, _vm._l(_vm.bookingLimit, function (bLimit, bLimitIndex) {
    return _c('b-form-checkbox', {
      key: "".concat(bLimit === null || bLimit === void 0 ? void 0 : bLimit.airlineSource, "-").concat(bLimitIndex),
      staticClass: "custom-control-success",
      attrs: {
        "name": "check-button",
        "inline": "",
        "disabled": !_vm.isEditPermission,
        "checked": bLimit === null || bLimit === void 0 ? void 0 : bLimit.enableBooking
      },
      on: {
        "change": function change(checked) {
          return _vm.changeBookingLimit(checked, bLimit.airlineSource);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$te("flight.sourceName.".concat(bLimit === null || bLimit === void 0 ? void 0 : bLimit.airlineSource.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(bLimit === null || bLimit === void 0 ? void 0 : bLimit.airlineSource.toUpperCase())) : bLimit === null || bLimit === void 0 ? void 0 : bLimit.airlineSource.toUpperCase()) + " ")]);
  }), 1) : _vm._e(), _vm.showIssueRefundVoidLimit ? _c('b-form-group', {
    staticClass: "mt-1",
    attrs: {
      "label": _vm.$t('agency.configs.issueLimit')
    }
  }, _vm._l(_vm.issueLimit, function (issue, issueIndex) {
    return _c('b-form-checkbox', {
      key: "".concat(issue === null || issue === void 0 ? void 0 : issue.airlineSource, "-").concat(issueIndex),
      staticClass: "custom-control-success",
      attrs: {
        "name": "check-button",
        "inline": "",
        "disabled": !_vm.isEditPermission,
        "checked": issue === null || issue === void 0 ? void 0 : issue.allowance
      },
      on: {
        "change": function change(checked) {
          return _vm.changeIssueLimit(checked, issue.airlineSource);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$te("flight.sourceName.".concat(issue === null || issue === void 0 ? void 0 : issue.airlineSource.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(issue === null || issue === void 0 ? void 0 : issue.airlineSource.toUpperCase())) : issue === null || issue === void 0 ? void 0 : issue.airlineSource.toUpperCase()) + " ")]);
  }), 1) : _vm._e(), _vm.showIssueRefundVoidLimit && _vm.agencyIsF2 ? _c('b-form-group', {
    staticClass: "mt-1",
    attrs: {
      "label": _vm.$t('agency.configs.refundLimit')
    }
  }, _vm._l(_vm.refundLimit, function (refund, refundIndex) {
    return _c('b-form-checkbox', {
      key: "".concat(refund === null || refund === void 0 ? void 0 : refund.airlineSource, "-").concat(refundIndex),
      staticClass: "custom-control-success",
      attrs: {
        "name": "check-button",
        "inline": "",
        "disabled": !_vm.isEditPermission,
        "checked": refund === null || refund === void 0 ? void 0 : refund.allowance
      },
      on: {
        "change": function change(checked) {
          return _vm.changeRefundLimit(checked, refund.airlineSource);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$te("flight.sourceName.".concat(refund === null || refund === void 0 ? void 0 : refund.airlineSource.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(refund === null || refund === void 0 ? void 0 : refund.airlineSource.toUpperCase())) : refund === null || refund === void 0 ? void 0 : refund.airlineSource.toUpperCase()) + " ")]);
  }), 1) : _vm._e(), _vm.showIssueRefundVoidLimit && _vm.agencyIsF2 ? _c('b-form-group', {
    staticClass: "mt-1",
    attrs: {
      "label": _vm.$t('agency.configs.voidLimit')
    }
  }, _vm._l(_vm.voidLimit, function (voidItem, voidIndex) {
    return _c('b-form-checkbox', {
      key: "".concat(voidItem === null || voidItem === void 0 ? void 0 : voidItem.airlineSource, "-").concat(voidIndex),
      staticClass: "custom-control-success",
      attrs: {
        "name": "check-button",
        "inline": "",
        "disabled": !_vm.isEditPermission,
        "checked": voidItem === null || voidItem === void 0 ? void 0 : voidItem.allowance
      },
      on: {
        "change": function change(checked) {
          return _vm.changeVoidLimit(checked, voidItem.airlineSource);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$te("flight.sourceName.".concat(voidItem === null || voidItem === void 0 ? void 0 : voidItem.airlineSource.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(voidItem === null || voidItem === void 0 ? void 0 : voidItem.airlineSource.toUpperCase())) : voidItem === null || voidItem === void 0 ? void 0 : voidItem.airlineSource.toUpperCase()) + " ")]);
  }), 1) : _vm._e()], 1)], 1)], 1), !_vm.isRoleF3 ? _c('b-card', {
    staticClass: "border my-1",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1 pb-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.featureTitle')) + " ")])];
      },
      proxy: true
    }], null, false, 2129197755)
  }, [_c('b-card-body', {
    staticClass: "p-0 d-flex-center justify-content-start mt-1"
  }, [_c('b-row', {
    staticClass: "w-100 gap-3"
  }, [_vm.agencyIsF2 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.enableLowFareTracking,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeActionSetLowFareTracking(_vm.agencyToEdit.id);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.enableSetLowFare')) + " ")])])], 1) : _vm._e(), _vm.agencyIsF2 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.enableBookingGroup,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.toggleEnableBookingGroup(_vm.agencyToEdit.id);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.enableBookingGroup')) + " ")])])], 1) : _vm._e(), (_vm$agencyToEdit2 = _vm.agencyToEdit) !== null && _vm$agencyToEdit2 !== void 0 && _vm$agencyToEdit2.parentAgency && ((_vm$agencyToEdit3 = _vm.agencyToEdit) === null || _vm$agencyToEdit3 === void 0 ? void 0 : (_vm$agencyToEdit3$par = _vm$agencyToEdit3.parentAgency) === null || _vm$agencyToEdit3$par === void 0 ? void 0 : _vm$agencyToEdit3$par.id) !== 1000000 && _vm.isRoleF2 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.sendMailAirline,
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": _vm.isF3Retail
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeSendMailAirline(_vm.agencyToEdit.id);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.sendMailAirlineOn')) + " ")])])], 1) : _vm._e(), (_vm$agencyToEdit4 = _vm.agencyToEdit) !== null && _vm$agencyToEdit4 !== void 0 && _vm$agencyToEdit4.parentAgency && ((_vm$agencyToEdit5 = _vm.agencyToEdit) === null || _vm$agencyToEdit5 === void 0 ? void 0 : (_vm$agencyToEdit5$par = _vm$agencyToEdit5.parentAgency) === null || _vm$agencyToEdit5$par === void 0 ? void 0 : _vm$agencyToEdit5$par.id) !== 1000000 && _vm.isRoleF2 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.sendPhoneAirline,
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": _vm.isF3Retail
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeSendPhoneAirline(_vm.agencyToEdit.id);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.sendPhoneAirline')) + " ")])])], 1) : _vm._e(), _vm.agencyIsF2 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.enableQuickName,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeEnableQuickName(_vm.agencyToEdit.id);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.enableQuickName')) + " ")])])], 1) : _vm._e(), _vm.agencyIsF2 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.enableRebook,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeEnableRebook(_vm.agencyToEdit.id);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.enableRebook')) + " ")])])], 1) : _vm._e(), _vm.agencyIsF2 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.limitTimeSearch.enable,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeLimitTimeSearch(_vm.agencyToEdit.id, !_vm.agencyToEdit.limitTimeSearch.enable, _vm.agencyToEdit.limitTimeSearch.seconds);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.limitTimeSearch')) + " "), _c('em', [_vm._v("(giây)")])])]), _vm.agencyToEdit.limitTimeSearch.enable ? _c('div', {
    staticClass: "d-flex"
  }, [_c('BFormInput', {
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "type": "number",
      "min": "0",
      "max": "30"
    },
    model: {
      value: _vm.agencyToEdit.limitTimeSearch.seconds,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyToEdit.limitTimeSearch, "seconds", $$v);
      },
      expression: "agencyToEdit.limitTimeSearch.seconds"
    }
  }), _vm.agencyToEdit.limitTimeSearch.seconds !== _vm.agenciesData.limitTimeSearch.seconds ? _c('div', {
    staticClass: "d-flex flex-nowrap mx-50 gap-2"
  }, [_c('b-button', {
    staticClass: "text-center px-75",
    attrs: {
      "variant": "flat-success"
    },
    on: {
      "click": function click($event) {
        return _vm.changeLimitTimeSearch(_vm.agencyToEdit.id, _vm.agencyToEdit.limitTimeSearch.enable, _vm.agencyToEdit.limitTimeSearch.seconds);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckIcon",
      "size": "16"
    }
  })], 1), _c('b-button', {
    staticClass: "text-center px-75",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": function click($event) {
        _vm.agencyToEdit.limitTimeSearch.seconds = _vm.agenciesData.limitTimeSearch.seconds;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon",
      "size": "16"
    }
  })], 1)], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.agencyIsF2 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-checkbox', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.limitPaxBooking.enable,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeLimitPaxBooking(_vm.agencyToEdit.id, !_vm.agencyToEdit.limitPaxBooking.enable, _vm.agencyToEdit.limitPaxBooking.paxLength);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.limitPaxBooking')) + " ")])]), _vm.agencyToEdit.limitPaxBooking.enable ? _c('div', {
    staticClass: "d-flex"
  }, [_c('BFormInput', {
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "type": "number",
      "min": "1",
      "max": "9"
    },
    model: {
      value: _vm.agencyToEdit.limitPaxBooking.paxLength,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyToEdit.limitPaxBooking, "paxLength", $$v);
      },
      expression: "agencyToEdit.limitPaxBooking.paxLength"
    }
  }), _vm.agencyToEdit.limitPaxBooking.paxLength !== _vm.agenciesData.limitPaxBooking.paxLength ? _c('div', {
    staticClass: "d-flex flex-nowrap mx-50 gap-2"
  }, [_c('b-button', {
    staticClass: "text-center px-75",
    attrs: {
      "variant": "flat-success"
    },
    on: {
      "click": function click($event) {
        return _vm.changeLimitPaxBooking(_vm.agencyToEdit.id, _vm.agencyToEdit.limitPaxBooking.enable, _vm.agencyToEdit.limitPaxBooking.paxLength);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckIcon",
      "size": "16"
    }
  })], 1), _c('b-button', {
    staticClass: "text-center px-75",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": function click($event) {
        _vm.agencyToEdit.limitPaxBooking.paxLength = _vm.agenciesData.limitPaxBooking.paxLength;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon",
      "size": "16"
    }
  })], 1)], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.isRoleF2 && _vm.agencyIsF3 ? _c('b-card', {
    staticClass: "border my-1",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1 pb-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.feeTitle')) + " ")])];
      },
      proxy: true
    }], null, false, 156374633)
  }, [_c('b-row', {
    staticClass: "w-100 gap-3"
  }, [_c('b-col', {
    staticClass: "d-flex-center justify-content-start mt-75",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.hideFee,
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": _vm.isF3Retail
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeHideFeeConfig(_vm.agencyToEdit.id);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.hideFee')) + " ")])])], 1)], 1)], 1) : _vm._e(), !_vm.isRoleF3 ? _c('b-card', {
    staticClass: "border mb-0",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-50 mb-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between w-100"
        }, [_c('h6', {
          staticClass: "text-black m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.agenciesFeeOnly', {
          floor: _vm.agencyIsF2 ? 'F2' : _vm.agencyIsF3 ? 'F3' : ''
        })) + " ")]), _vm.isShowChangeFeeAgencyConfigBtn ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon mr-1 py-25 px-50 d-flex-center",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": _vm.editAgencyFee
          }
        }, [_c('img', {
          attrs: {
            "src": require("@icons/edit.svg")
          }
        }), !_vm.isMobileView ? _c('span', {
          staticClass: "font-medium-2 ml-25",
          staticStyle: {
            "margin-top": "1px"
          }
        }, [_vm._v(" Thay đổi phí ")]) : _vm._e()]) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 333931381)
  }, [(_vm$agencyToEdit6 = _vm.agencyToEdit) !== null && _vm$agencyToEdit6 !== void 0 && _vm$agencyToEdit6.agencyFee ? _c('div', {
    staticClass: "mx-1"
  }, [!_vm.agencyToEdit.applyAgencyFee ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-alert', {
    class: "py-50 px-1 shadow-sm fw-700 d-flex-center ".concat(_vm.isMobileView ? 'flex-column' : ''),
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_c('b', [_vm._v("Đại lý này đang sử dụng cấu hình phí chung")])]), _c('b-alert', {
    class: "py-50 px-1 shadow-sm fw-700 d-flex-center ".concat(_vm.isMobileView ? 'flex-column' : ''),
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('feather-icon', {
    class: "text-warning mr-md-1",
    attrs: {
      "icon": "AlertTriangleIcon",
      "size": "20"
    }
  }), _c('b', [_vm._v("Chưa áp dụng phí xuất vé riêng")])], 1)], 1) : _vm._e(), _vm.agencyToEdit.applyAgencyFee ? _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "MapPinIcon",
            "size": "16"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('fee.domesticTicket')))])];
      },
      proxy: true
    }], null, false, 1863146498)
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "striped": "",
      "hover": "",
      "small": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "items": _vm.getItemsFee(_vm.agenciesData.agencyFee, 'INLAND')
    },
    scopedSlots: _vm._u([{
      key: "head(distributor)",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.$t('fee.distributor')))])];
      },
      proxy: true
    }, {
      key: "head(feeType)",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('fee.feeType')) + " ")];
      },
      proxy: true
    }, {
      key: "head(ticketType)",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('fee.ticketType')) + " ")];
      },
      proxy: true
    }, {
      key: "head(amount)",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('fee.amount')) + " ")];
      },
      proxy: true
    }, {
      key: "cell(distributor)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex-center gap-1 font-weight-bolder"
        }, [_c('div', {
          staticClass: "d-flex-center",
          staticStyle: {
            "width": "50px"
          }
        }, [_c('IAmLogoAirline', {
          attrs: {
            "airline": item.distributor.toUpperCase(),
            "height": 30
          }
        })], 1), _c('span', {
          staticClass: "flex-1 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$te("flight.airlines.".concat(item.distributor.toUpperCase())) ? _vm.$t("flight.airlines.".concat(item.distributor.toUpperCase())) : '') + " (" + _vm._s(_vm.$te("flight.sourceName.".concat(item.distributor.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(item.distributor.toUpperCase())) : item.distributor.toUpperCase()) + ") ")])])];
      }
    }, {
      key: "cell(feeType)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("fee.".concat(data.item.feeType))) + " ")];
      }
    }, {
      key: "cell(ticketType)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("fee.".concat(data.item.ticketType))) + " ")];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.amount)) + " ")])];
      }
    }], null, false, 3773855480)
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "NavigationIcon",
            "size": "16"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('fee.internationalTicket')))])];
      },
      proxy: true
    }], null, false, 1782941463)
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "striped": "",
      "hover": "",
      "small": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "items": _vm.getItemsFee(_vm.agenciesData.agencyFee, 'INTERNATIONAL')
    },
    scopedSlots: _vm._u([{
      key: "head(distributor)",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.$t('fee.distributor')))])];
      },
      proxy: true
    }, {
      key: "head(feeType)",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('fee.feeType')) + " ")];
      },
      proxy: true
    }, {
      key: "head(ticketType)",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('fee.ticketType')) + " ")];
      },
      proxy: true
    }, {
      key: "head(amount)",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('fee.amount')) + " ")];
      },
      proxy: true
    }, {
      key: "cell(distributor)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex-center gap-1 font-weight-bolder"
        }, [_c('div', {
          staticClass: "d-flex-center",
          staticStyle: {
            "width": "50px"
          }
        }, [_c('IAmLogoAirline', {
          attrs: {
            "airline": item.distributor.toUpperCase(),
            "height": 30
          }
        })], 1), _c('span', {
          staticClass: "flex-1 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$te("flight.airlines.".concat(item.distributor.toUpperCase())) ? _vm.$t("flight.airlines.".concat(item.distributor.toUpperCase())) : '') + " (" + _vm._s(_vm.$te("flight.sourceName.".concat(item.distributor.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(item.distributor.toUpperCase())) : item.distributor.toUpperCase()) + ") ")])])];
      }
    }, {
      key: "cell(feeType)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("fee.".concat(data.item.feeType))) + " ")];
      }
    }, {
      key: "cell(ticketType)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("fee.".concat(data.item.ticketType))) + " ")];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(_vm.formatCurrency(data.item.amount)))])];
      }
    }], null, false, 221189080)
  })], 1)], 1) : _vm._e()], 1) : _c('div', [_vm._v(" Chưa cài phí xuất vé cho F3 ")])]) : _vm._e(), _vm.isF3Retail || _vm.isRoleF1 ? _c('b-card', {
    staticClass: "border my-1",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1 pb-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.retailTitle')) + " ")])];
      },
      proxy: true
    }], null, false, 1400290952)
  }, [_c('b-card-body', {
    staticClass: "p-0 d-flex-center justify-content-start mt-1"
  }, [_c('b-row', {
    staticClass: "w-100 gap-3"
  }, [_vm.agencyIsF2 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.autoDeposit,
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": false
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.updateSwitch('autoDeposit');
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.autoDeposit')) + " ")])])], 1) : _vm._e(), _vm.agencyIsF3 ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.autoIssueTicket,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.updateSwitch('autoIssueTicket');
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.autoIssueTicket')) + " ")])])], 1) : _vm._e(), _vm.isF3Retail ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.enableDraft,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeDisableDraft(_vm.agencyToEdit.enableDraft);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.agencyToEdit.enableDraft ? _vm.$t('agency.configs.enableDraft') : _vm.$t('agency.configs.disableDraft')) + " ")])])], 1) : _vm._e(), _vm.isF3Retail && _vm.agencyToEdit.agencyRetailConfig ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.agencyRetailConfig.usePhoneCustomer,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.updateSwitch('usePhoneCustomer');
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.usePhoneCustomer')) + " ")])])], 1) : _vm._e(), _vm.agencyToEdit.agencyRetailConfig && (_vm.isF3Retail || _vm.agencyIsF2) ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.agencyRetailConfig.enable1G,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.updateSwitch('enable1G');
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.enable1G')) + " ")])])], 1) : _vm._e(), _vm.isF3Retail && _vm.agencyToEdit.agencyRetailConfig ? _c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": _vm.agencyToEdit.agencyRetailConfig.showTicketCondition,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.updateSwitch('showTicketCondition');
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.showTicketCondition')) + " ")])])], 1) : _vm._e(), _vm.isF3Retail && _vm.agencyToEdit.agencyRetailConfig ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-sm-flex d-flex-col gap-1"
  }, [_c('b-form-group', {
    staticClass: "mb-1",
    attrs: {
      "label": _vm.$t('agency.configs.paymentMethod'),
      "disabled": !_vm.isEditRetailConfig
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "name": "check-button",
      "inline": ""
    },
    model: {
      value: _vm.agencyToEdit.agencyRetailConfig.showPaymentOffice,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyToEdit.agencyRetailConfig, "showPaymentOffice", $$v);
      },
      expression: "agencyToEdit.agencyRetailConfig.showPaymentOffice"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("agency.configs.showPaymentOffice")) + " ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "name": "check-button",
      "inline": ""
    },
    model: {
      value: _vm.agencyToEdit.agencyRetailConfig.showPaymentHome,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyToEdit.agencyRetailConfig, "showPaymentHome", $$v);
      },
      expression: "agencyToEdit.agencyRetailConfig.showPaymentHome"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("agency.configs.showPaymentHome")) + " ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "name": "check-button",
      "inline": ""
    },
    model: {
      value: _vm.agencyToEdit.agencyRetailConfig.showPaymentQR,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyToEdit.agencyRetailConfig, "showPaymentQR", $$v);
      },
      expression: "agencyToEdit.agencyRetailConfig.showPaymentQR"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("agency.configs.showPaymentQR")) + " ")])], 1), _c('div', [!_vm.isEditRetailConfig ? _c('BButton', {
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        _vm.isEditRetailConfig = !_vm.isEditRetailConfig;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('edit')) + " ")]) : _c('BButton', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.loadingRetailConfig,
      "size": "sm"
    },
    on: {
      "click": _vm.handleEditRetailConfig
    }
  }, [_vm.loadingRetailConfig ? _c('b-spinner', {
    attrs: {
      "variant": "light",
      "label": "Text Centered",
      "small": "",
      "tag": "span"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.loadingRetailConfig ? _vm.$t('saving') : _vm.$t('save')) + " ")], 1)], 1)], 1)]) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.isRoleF1 ? _c('b-card', {
    staticClass: "border my-1",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1 pb-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.titleSetting')) + " ")])];
      },
      proxy: true
    }], null, false, 2023640708)
  }, [_c('b-card-body', {
    staticClass: "p-0 d-flex-center justify-content-start mt-1"
  }, [_c('b-row', {
    staticClass: "w-100 gap-3"
  }, [_vm.agencyIsF2 ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [new Date(_vm.agenciesData.expiredDateBanMkp) > new Date() ? _c('div', {
    staticClass: "text-danger"
  }, [new Date(_vm.agenciesData.expiredDateBanMkp) - new Date() > 100 * 365.25 * 24 * 60 * 60 * 1000 ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" Cấm vĩnh viễn ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.banTo')) + ": " + _vm._s(_vm.convertISODateTime(_vm.agenciesData.expiredDateBanMkp).dateTime) + " ")])]) : _vm._e(), _c('br'), _c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "checked": new Date(_vm.agenciesData.expiredDateBanMkp) > new Date(),
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onBan($event);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.ban')) + " ")])])], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), !_vm.isRoleF3 ? _c('ModalUpdateAgencyFee', {
    attrs: {
      "agencies-data": _vm.agenciesData,
      "is-edit-place": true
    },
    on: {
      "refetchAgency": _vm.refetchAgency
    }
  }) : _vm._e(), _vm.agencyToEdit ? _c('ModalDisableDraft', {
    attrs: {
      "agency-to-edit": _vm.agencyToEdit
    },
    on: {
      "update-enable-draft": _vm.updateEnableDraft
    }
  }) : _vm._e(), _c('BanAGModal', {
    attrs: {
      "ag-id": (_vm$agenciesData = _vm.agenciesData) === null || _vm$agenciesData === void 0 ? void 0 : _vm$agenciesData.id
    },
    on: {
      "refresh": _vm.refetchAgency
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }